// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import map from 'lodash/map'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Gallery from 'react-photo-gallery'
import GalleryX from '@bodhi-project/components/lib/gatsby/Gallery'
import MediaQuery from 'react-responsive'

import ConfigProvider from 'antd/lib/config-provider'
import '@bodhi-project/antrd/lib/4.10.3/config-provider/style/css'

import getRandomArbitraryInt from '@bodhi-project/components/lib/methods/getRandomArbitraryInt'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'

import Division from '@bodhi-project/components/lib/division'
import '@bodhi-project/components/lib/division/style.less'

import Row from 'antd/lib/row'
import '@bodhi-project/antrd/lib/4.10.3/row/style/css'

import Col from 'antd/lib/col'
import '@bodhi-project/antrd/lib/4.10.3/col/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

import seoHelper from '../methods/seoHelper'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const pageData = {
  pageTitle: 'Prisma Arts',
  nakedPageSlug: '',
  pageAbstract:
    'Prisma Arts curates and offers inspirational and contemporary art from Auroville, India and beyond for art lovers and collectors across the globe.',
}

const seoData = seoHelper(pageData)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    img7: file(relativePath: { eq: "olaf/img7.jpg" }) {
      ...max1200
    }
    img8: file(relativePath: { eq: "olaf/img8.jpg" }) {
      ...max1200
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = props => (
  <ConfigProvider prefixCls="wtf">
    <StandardPageWrapper
      className="small-container"
      seoData={seoData}
      {...props}
    >
      <h2>Featured artworks</h2>
      <div style={{ maxHeight: '88vh' }}>
        <Img
          fluid={props.data.img7.childImageSharp.fluid}
          durationFadeIn={getRandomArbitraryInt(150, 450)}
          style={{
            maxHeight: '88vh',
            padding: '1rem',
          }}
          imgStyle={{
            maxHeight: 'calc(88vh - 2rem)',
            marginTop: '1rem',
            objectFit: 'contain',
            objectPosition: 'center center',
          }}
        />
      </div>
      <p style={{ marginLeft: 'auto', textAlign: 'right' }}>
        <strong>Objet d'art #11</strong>
        <br />
        Divine Hanuman, Olaf Van Cleef
      </p>

      {/*
        <div style={{ marginBottom: '2rem' }}>
          <GalleryX
            data={props.data}
            lookup="img"
            Img={Img}
            Gallery={Gallery}
            MediaQuery={MediaQuery}
            galleryProps={{
              columns: 2,
              direction: 'column',
              // margin: 10,
              // targetRowHeight: 900,
            }}
          />
        </div>
      <div>
        <Division Row={Row} Col={Col}>
          <div>
            <h2>Services for buyers</h2>
            <p>
              Customised art prints, posters, merchandises and other
              art-inspired objects are made on demand, featuring original
              artwork, rare photographs and exquisite creations from Auroville,
              India and beyond, and shipped across India, and to most countries
              internationally.
            </p>
          </div>
          <div>
            <h2>Services for creators</h2>
            <p>
              Prisma Arts offers you an Auroville-based network of trusted
              services helping you plan, produce, print, frame, ship, and sell
              your own art prints. We offer those who need it, the access to our
              own network of printers, creators and service providers.
            </p>
          </div>
        </Division>
      </div>*/}
    </StandardPageWrapper>
  </ConfigProvider>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
